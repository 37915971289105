import React from "react";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {userSchema} from "../../resources/validationSchemas";
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import PasswordChange from "./PasswordChange";
import {UserProfileDetailsSkeleton} from "./UserProfileDetailsSkeleton";
import {useMutation, useQuery} from "@apollo/client";
import {
  GET_USER,
  UPDATE_USER,
  GET_VERSION,
  GET_NEWS_SUBSCRIBER,
  UPDATE_NEWS_SUBSCRIBER,
} from "./queries";
import {version} from "../../../package.json";
import CustomSwitch from "./CustomSwitch";
import {isEmpty, keys, map, isUndefined} from "underscore";

const UserProfileDetails = () => {
  const {updateCustomerProfile, user} = useLogin();
  const formMethods = useForm({
    defaultValues: {email: user.email},
    resolver: yupResolver(userSchema.required()),
  });

  const {loading} = useQuery(GET_USER, {
    variables: {username: user.email},
    onCompleted: (data) => {
      map(data.user.UserAttributes, (attribute) => {
        formMethods.setValue(attribute.Name.replace("custom:", ""), attribute.Value);
      });
    },
  });
  const {data: versionData, loading: versionLoading} = useQuery(GET_VERSION);
  const [updateUser, updateUserMutation] = useMutation(UPDATE_USER);

  const profile = {
    customerName: {label: "Name", value: user.profile?.["custom:customerName"] ?? ""},
    orgFullName: {label: "Organization Name", value: user.profile?.["custom:orgFullName"] ?? ""},
    orgTitle: {label: "Title", value: user.profile?.["custom:orgTitle"] ?? ""},
  };

  const {data: newsSubscriberData} = useQuery(GET_NEWS_SUBSCRIBER, {
    variables: {username: user.email},
  });
  const isNewsSubscriber = newsSubscriberData?.newsSubscriber?.result;
  const [updateUserNewsSubscriber] = useMutation(UPDATE_NEWS_SUBSCRIBER);

  const handleUpdate = async (data) => {
    if (data.isSubscribing !== isNewsSubscriber) {
      await updateUserNewsSubscriber({
        variables: {
          username: user.email,
          isSubscribing: data.isSubscribing,
        },
      });
    }
    await updateUser({variables: {username: user.email, ...data}});
    if (!isEmpty(data.customerName)) updateCustomerProfile(data);
  };

  if (loading || versionLoading) return <UserProfileDetailsSkeleton />;

  const formErrors = formMethods?.formState?.errors;

  return <>
    <Box p={3} width="100%" id="user-profile-details">
      <Typography variant="h4">Manage User Profile</Typography>
      <Box mt={3} mb={2}>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleUpdate)}>
            {map(keys(profile), (profileItem, index) => {
              const label = profile[profileItem].label;
              const value = profile[profileItem].value;
              const error = formErrors?.[profileItem]?.message;
              return (
                <Box key={index} mb={2}>
                  <TextField
                    label={label}
                    {...formMethods.register(profileItem)}
                    key={`${index}-${profileItem}`}
                    id={`profile.${index}.${profileItem}`}
                    defaultValue={value}
                    fullWidth
                    error={!!error}
                    helperText={error}
                  />
                </Box>
              );
            })}
            {!isUndefined(isNewsSubscriber) && (
              <Box mt={2} mb={2}>
                <Divider />
                <Typography variant="h6" sx={{marginBottom: 2, marginTop: 2}}>
                  Subscribe to News Feed Emails
                </Typography>
                <CustomSwitch
                  name="isSubscribing"
                  defaultChecked={isNewsSubscriber}
                  label="Subscribe to News Feed Emails"
                />
              </Box>
            )}
            <Button
              type="submit"
              id="submit"
              variant="outlined"
              disabled={updateUserMutation.loading}
            >
              Update Profile
            </Button>
          </form>
        </FormProvider>
        {!isEmpty(updateUserMutation.error) &&
          <Typography color="error" sx={{marginTop: 2}}>
            An error occurred. Please try again later.
          </Typography>
        }
      </Box>
      <Divider />
      <PasswordChange />
      <Typography variant="caption" align="right" width="100%" display="block">
        UI: {version}<br />
        API: {versionData?.version ?? "Unknown"}
      </Typography>
    </Box>
  </>;
};

export default UserProfileDetails;
