import React from "react";
import PropTypes from "prop-types";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {CssBaseline, Box} from "@mui/material";
import UpgradePrintMessage from "./src/components/upgrade/UpgradePrintMessage";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {MetricsProvider} from "./src/components/metrics/MetricsProvider";
import {ConsolidatedThemeProvider} from "./src/resources/ConsolidatedThemeProvider";
import CoreBanner from "./src/components/banners/CoreBanner";
import RegulationsCore from "./src/pages/core";
import {initializeDayJS} from "./src/resources/environment";
import Chatbot from "./src/components/chatbot/Chatbot";

initializeDayJS(); // needed to be out of useEffect possibly related to React 18 changes

const App = ({metrics}) => (
  <BrowserRouter>
    <CssBaseline />
    <ConsolidatedThemeProvider>
      <MetricsProvider metrics={metrics}>
        <AppContent />
      </MetricsProvider>
    </ConsolidatedThemeProvider>
  </BrowserRouter>
);

App.propTypes = {
  metrics: PropTypes.object,
};

const AppContent = () => {
  const {isPaid} = useLogin();

  const handleCopy = (event) => {
    if (isPaid) return;
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    const copiedText = `To copy and paste, please upgrade to a paid subscription via the Manage 
      Subscription page.`;
    navigator.clipboard.writeText(copiedText);
  };

  return (
    <>
      <Box onCopy={handleCopy} sx={{"@media print": {display: isPaid ? undefined : "none"}}}>
        <CoreBanner />
        <Routes>
          <Route path="/*" element={<RegulationsCore />} />
        </Routes>
      </Box>
      <Box sx={{display: "none", "@media print": {display: "block"}}} p={3}>
        {isPaid ? null : <UpgradePrintMessage />}
      </Box>
      {isPaid ? <Chatbot /> : null}
    </>
  );
};

export default App;

