import React, {useEffect, useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {UserProfileDetailsSkeleton} from "./UserProfileDetailsSkeleton";
import axios from "axios";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {isEmpty} from "underscore";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const PasswordChange = () => {
  const formMethods = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(yup.object().shape({
      currentPassword: yup.string().required("Current password is required."),
      newPassword: yup.string().required("Password is required.")
          .min(10, "Password must contain 10 or more characters.")
          .minLowercase(1, "Password must contain at least 1 lowercase character.")
          .minUppercase(1, "Password must contain at least 1 uppercase character.")
          .minNumbers(1, "Password must contain at least 1 number.")
          .minSymbols(1, "Password must contain at least 1 special character."),
      confirmPassword: yup.string().required("Confirm password is required."),
    })),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState({});
  const {user} = useLogin();

  useEffect(() => {
    if (submitMessage?.type !== "success") return;
    return () => setTimeout(() => setSubmitMessage({}), 5000);
  }, [submitMessage]);

  const onChangePassword = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      formMethods.setError("confirmPassword", {type: "custom", message: "Passwords do not match"});
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${AUTH_ENDPOINT}/password/change`, {
        accessToken: user.AccessToken,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      });
      setSubmitMessage({type: "success", message: "Password changed successfully."});
    } catch (error) {
      setSubmitMessage({type: "error", message: error?.message ?? "Password change failed."});
    } finally {
      setIsLoading(false);
      formMethods.reset();
    }
  };

  if (isLoading) return <UserProfileDetailsSkeleton padding={0} />;

  return (
    <Box mt={2}>
      <FormProvider {...formMethods}>
        <Typography variant="h4" sx={{marginBottom: 2}}>
          Change your current password
        </Typography>
        <form onSubmit={formMethods.handleSubmit(onChangePassword)}>
          <TextField
            {...formMethods.register("currentPassword", {required: true})}
            label="Current Password"
            type="password"
            id="current-password"
            fullWidth
            sx={{marginBottom: 2}}
            error={!!formMethods.formState.errors?.currentPassword?.message}
            helperText={formMethods.formState.errors?.currentPassword?.message}
          />
          <TextField
            {...formMethods.register("newPassword", {required: true})}
            label="New Password"
            type="password"
            id="new-password"
            fullWidth
            sx={{marginBottom: 2}}
            error={!!formMethods.formState.errors?.newPassword?.message}
            helperText={formMethods.formState.errors?.newPassword?.message}
          />
          <TextField
            {...formMethods.register("confirmPassword", {required: true})}
            label="Confirm New Password"
            type="password"
            id="confirm-new-password"
            fullWidth
            sx={{marginBottom: 2}}
            error={!!formMethods.formState.errors?.confirmPassword?.message}
            helperText={formMethods.formState.errors?.confirmPassword?.message}
          />
          <Button
            type="submit"
            id="new-password-submit"
            variant="outlined"
            sx={{marginBottom: 1}}
          >
            Change Password
          </Button>
        </form>
      </FormProvider>
      {!isEmpty(submitMessage) &&
        <Typography variant="caption" color={submitMessage?.type === "success" ? "green" : "error"}>
          {submitMessage?.message ?? ""}
        </Typography>
      }
    </Box>
  );
};

export default PasswordChange;
