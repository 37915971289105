import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Box, Button, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Close from "@mui/icons-material/Close";
import {useLogin} from "./LoginProvider";
import {yupResolver} from "@hookform/resolvers/yup";
import {isString} from "underscore";
import {useLoginStyles} from "./LoginStyles";

const MFAChallengeForm = () => {
  const {isDarkThemeSaved, isSubmitting, mfaSchema, setMFAChallenge, submitMfa} = useLogin();
  const {classes} = useLoginStyles({isDarkThemeSaved});
  const formMethods = useForm(({resolver: yupResolver(mfaSchema)}));

  const errorMessage = isString(formMethods?.formState?.errors?.mfaCode?.message) ?
    formMethods.formState.errors.mfaCode.message.replace("mfaCode", "Code") : null;

  const handleSubmit = async (data) => {
    await submitMfa(data, formMethods);
  };

  const handleClose = () => {
    setMFAChallenge({});
  };

  return (
    <Box className={classes.wrapper}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Paper elevation={10} sx={{borderRadius: 0}}>
            <Box className={classes.closeIconBox}>
              <Close
                onClick={handleClose}
                id="close"
                className={classes.closeIcon}
                sx={{color: "#808080", cursor: "pointer"}}
              />
            </Box>
            <Box className={classes.loginPaper} sx={{paddingTop: "unset !important"}}>
              <center>
                <Button disabled className={classes.logo} aria-label="BreachRx" />
                <Typography id="tagline" variant="h5" className={classes.tagline}>
                  Login to Cyber RegScout&trade;
                </Typography>
              </center>
              <TextField
                {...formMethods.register("mfaCode", {required: true})}
                id="mfaCode"
                label="One-Time Code"
                required
                variant="outlined"
                size="medium"
                autoComplete="off"
                autoFocus
                sx={{marginTop: 4, marginBottom: 2}}
                InputLabelProps={{sx: {userSelect: "none"}}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined className={classes.adornmentIcon} />
                    </InputAdornment>
                  ),
                  sx: {borderRadius: 0},
                }}
                className={classes.loginTextField}
              />
              <Button
                type="submit"
                id="submit"
                variant="outlined"
                className={classes.submit}
                sx={isSubmitting ? {
                  "@keyframes blink": {"from, to": {opacity: 1}, "50%": {opacity: 0}},
                  animation: "blink 1s ease-in infinite",
                } : undefined}
              >
                Submit
              </Button>
              <Box
                display={!errorMessage ? "none" : "flex"}
                justifyContent="center"
                alignItems="center"
                marginTop={1}
              >
                <Typography variant="caption" color="error" className={classes.loginMessages}>
                  {errorMessage}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </form>
      </FormProvider>
    </Box>
  );
};

export default MFAChallengeForm;
