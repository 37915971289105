import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {Box, Typography} from "@mui/material";
import ListCard from "./ListCard";
import UpgradeBanner from "../../../components/upgrade/UpgradeBanner";
import {useRegulations} from "../contexts/RegulationsProvider";
import {useRegulationsStyles} from "./styles/Styles";
import {ListSkeleton} from "./ListSkeleton";
import {intersection, isEmpty, map, pluck, reject, uniq} from "underscore";

const List = ({customList, customNavigateState}) => {
  const {groups} = useLogin();
  const {regulationsListData, listQueryLoading, hideFilteredItems} = useRegulations();
  const {classes} = useRegulationsStyles();

  const moreRegulationsAvailable = isEmpty(intersection(groups, [
    "Admin", "Global", "Enterprise",
  ]));

  let areAllItemsHidden = false;
  if (hideFilteredItems && !moreRegulationsAvailable) {
    const allRegFilteredValues = uniq(pluck(customList ?? regulationsListData, "filtered"));
    const areAnyValuesNotFiltered = reject(allRegFilteredValues, (isFiltered) => isFiltered);
    areAllItemsHidden = isEmpty(areAnyValuesNotFiltered);
  }

  const cardList = useMemo(() => {
    const renderedList = map(customList ?? regulationsListData, (regulation, index) => (
      <ListCard
        regulation={regulation}
        customList={customList}
        customNavigateState={customNavigateState}
        key={index}
      />
    ));
    if (moreRegulationsAvailable) {
      const fakeRegulation = {
        ...customList?.[0] ?? regulationsListData?.[0],
        regulator: "Upgrade",
        geography: [],
        tags: [{tag: {isDeleted: false, value: "\"100\"", type: {name: "RiskScore"}}}],
      };
      renderedList.splice(2, 0, (
        <Box position="relative" id="upgrade-card-wrapper" key="upgrade-card-wrapper">
          <ListCard regulation={fakeRegulation} upgradeRequired />
          <UpgradeBanner
            message="More regulations are available. Ensure you have complete coverage."
          />
        </Box>
      ));
    }
    return renderedList;
  }, [regulationsListData, moreRegulationsAvailable]);

  if (listQueryLoading || !regulationsListData) return <ListSkeleton />;

  const noMatches = (
    <Box height="80vh" display="flex" flexDirection="row">
      <Typography variant="h4" color="primary" alignSelf="center" minWidth="100%">
        <center>
          No Cyber RegScout&trade; regulations match
        </center>
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.listWrapper} id="list-wrapper">
      {areAllItemsHidden ? noMatches : cardList}
    </Box>
  );
};

List.propTypes = {
  customList: PropTypes.array,
  customNavigateState: PropTypes.object,
};

export default List;
