import React from "react";
import {Box, List} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import FilterList from "../filters/FilterList";
import Sidebar from "./Sidebar";
import BackArrowListItem from "../BackArrowListItem";
import {useRegulations} from "../../pages/regulations/contexts/RegulationsProvider";

const SidebarForAllViews = () => {
  const {sidebarOpen} = useRegulations();
  const location = useLocation();
  const onRegulationList = location.pathname === "/";
  const navigate = useNavigate();

  const goBackToRegulations = () => {
    navigate("/", {state: location?.state});
  };

  const sidebarOpenContent = (
    <List component="nav" aria-label="Sort Regulations">
      <BackArrowListItem title="Back to Regulations" onClick={goBackToRegulations} />
    </List>
  );

  const sidebarClosedContent = onRegulationList ? null : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mt={1}
    >
      <BackArrowListItem title="Back to Regulations" onClick={goBackToRegulations} iconOnly />
    </Box>
  );

  const sidebarContent = onRegulationList && sidebarOpen ? <FilterList /> :
    sidebarOpen ? sidebarOpenContent : sidebarClosedContent;

  return (
    <Sidebar content={sidebarContent} />
  );
};

export default SidebarForAllViews;
