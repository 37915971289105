import React, {useState} from "react";
import {Box} from "@mui/material";
import FilterList from "./FilterList";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {useRegulations} from "../../pages/regulations/contexts/RegulationsProvider";
import Fab from "@mui/material/Fab";
import {useFabStyles} from "./fabStyles";
import {Menu} from "@mui/material";

const FloatingFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {sidebarOpen} = useRegulations();
  const {classes} = useFabStyles({sidebarOpen});
  const open = !!anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (sidebarOpen) return null;

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.main}
        onClick={handleClick}
      >
        <FilterListOutlinedIcon />
      </Fab>
      <Menu
        id="floating-filter-menu"
        aria-labelledby="floating-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        PaperProps={{sx: {borderRadius: 0}}}
        MenuListProps={{role: "menu", sx: {padding: 0}}}
      >
        <Box width={320}>
          <FilterList fromFloatingFilter />
        </Box>
      </Menu>
    </>
  );
};

export default FloatingFilter;
