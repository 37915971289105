import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../resources/theme";

export const useFabStyles = makeStyles()((theme, {open}) => ({
  main: {
    position: "fixed",
    top: theme.spacing(2),
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      top: theme.spacing(1),
      marginLeft: theme.spacing(-1),
    },
  },
}));
