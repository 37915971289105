import {makeStyles} from "tss-react/mui";
import {smallDeviceWidth} from "../../../../resources/theme";

export const useRegulationsStyles = makeStyles()((theme) => ({
  card: {
    minHeight: theme.spacing(34),
    minWidth: theme.spacing(75),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  cardSkeleton: {
    height: theme.spacing(30),
    width: theme.spacing(96),
    maxWidth: "unset",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  description: {
    "*": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  filteredCard: {
    opacity: "30%",
  },
  listWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: theme.spacing(2),
  },
  cardHeader: {
    color: theme.palette.primary.main,
  },
  userFilterButton: {
    "&> .MuiButton-startIcon": {
      marginTop: "-1px",
    },
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1rem",
    },
  },
  viewButton: {
    [`@media (max-device-width: ${smallDeviceWidth})`]: {
      fontSize: "1rem",
    },
  },
}));
