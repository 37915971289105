import React from "react";
import {Box} from "@mui/material";
import List from "./List";
import FloatingFilter from "../../../components/filters/FloatingFilter";
import RegulationMap from "./RegulationMap";
import {useRegulations} from "../contexts/RegulationsProvider";

const Home = () => {
  const {mapOpen} = useRegulations();

  return (
    <Box display="flex" width={"100%"}>
      <Box p={3} sx={{
        flexGrow: 1,
        bgcolor: "background.default",
        minHeight: "100vh",
      }}>
        <FloatingFilter />
        <Box py={3} display="flex" justifyContent="center">
          <Box width={mapOpen ? "100%" : 800}>
            {mapOpen ? <RegulationMap /> : <List />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
