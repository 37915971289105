import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Box, ListItem, ListItemText, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {useTheme} from "../../resources/ConsolidatedThemeProvider";
import {useTypewriter} from "./useTypewriter";
import {formatResponse} from "./chatbotHelpers";
import {isEmpty, map} from "underscore";
import {useChatbotMessageStyles} from "./chatbotStyles";

const ChatbotMessage = ({message, messageListRef}) => {
  const {activeTheme} = useTheme();
  const isDarkTheme = activeTheme.palette.mode === "dark";
  const isHuman = message.sender === "Human";
  const {classes} = useChatbotMessageStyles({isHuman});

  const messageText = message.text || "";
  const typedMessage = useTypewriter(messageText, isHuman || message?.isGreeting || message?.isRendered ? 0 : 4);
  const formattedMessage = formatResponse(typedMessage, isDarkTheme);
  const isTypingComplete = typedMessage.length === messageText.length;

  useEffect(() => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    if (isTypingComplete && message) message["isRendered"] = true;
  }, [typedMessage, isTypingComplete]);

  return (
    <ListItem alignItems="flex-start" className={classes.chatbotMessageItem}>
      <Box className={classes.chatbotMessageWrapper}>
        <ListItemText
          primary={
            <span
              dangerouslySetInnerHTML={{
                __html: formattedMessage,
              }}
              className={classes.chatbotMessageText}
            />
          }
          className={classes.chatbotMessage}
        />
        {!isEmpty(message?.references) && isTypingComplete ?
          <Typography
            variant="overline"
            display="block"
            lineHeight={1.5}
            marginTop={2}
            className={classes.chatbotMessageText}
          >
            Learn More:
          </Typography> :
          null}
        {!isEmpty(message?.references) && isTypingComplete ?
          <ul style={{listStyleType: "none", padding: 0}}>
            {map(message?.references, (reference, index) => (
              <li key={index}>
                <Link
                  to={`/regulation/${reference.id}`}
                  className={classes.chatbotMessageText}
                  id="reference-link"
                >
                  <Typography
                    variant="caption"
                    className={classes.chatbotMessageText}
                  >
                    {reference.jurisdiction || `${index}`}
                  </Typography>
                </Link>
              </li>
            ))}
          </ul> :
          null
        }
      </Box>
    </ListItem>
  );
};

ChatbotMessage.propTypes = {
  message: PropTypes.object,
  messageListRef: PropTypes.object,
};

export default ChatbotMessage;
