import React from "react";
import PropTypes from "prop-types";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {Box, Typography} from "@mui/material";
import NewsItem from "./NewsItem";
import UpgradeBanner from "../../../components/upgrade/UpgradeBanner";
import {intersection, isEmpty, map} from "underscore";
import {useDetailStyles} from "./styles/detailStyles";

const RecentActionsUpgrade = () => {
  const {classes} = useDetailStyles();
  return (
    <Box position="relative">
      <UpgradeBanner message="Know what actions the regulator is taking to inform your decisions" />
      <Box className={classes.upgradeContainer}>
        {map(Array(5), (data, index) => (<li key={index}>
          Upgrade to a higher plan to see recent actions — with sources | <a href="#">links</a>.
        </li>))}
      </Box>
    </Box>
  );
};

const RecentActions = ({recentActions}) => {
  const {groups} = useLogin();
  const {classes} = useDetailStyles();

  const needsUpgrade = isEmpty(intersection(groups, [
    "Admin", "Professional", "Global", "Enterprise",
  ]));

  return (
    <Box>
      <Typography className={classes.title} variant="h5" id="recent-actions">
        Recent Actions
      </Typography>
      <ul className={classes.actionLinks}>
        {needsUpgrade ?
          <RecentActionsUpgrade /> :
          isEmpty(recentActions) ? "None" :
          map(recentActions, ({newsItem}, index) => <NewsItem newsItem={newsItem} key={index} />)
        }
      </ul>
    </Box>
  );
};

RecentActions.propTypes = {
  recentActions: PropTypes.array,
};

export default RecentActions;
