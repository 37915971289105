import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import {FormProvider, useForm} from "react-hook-form";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {useMutation} from "@apollo/client";
import {UPDATE_USER} from "../../pages/user/queries";
import {yupResolver} from "@hookform/resolvers/yup";
import {userSchema} from "../../resources/validationSchemas";
import {isEmpty} from "underscore";

const MandatoryUserDataModal = () => {
  const {updateCustomerProfile, user} = useLogin();
  const formMethods = useForm({
    defaultValues: {
      orgFullName: user.profile?.["custom:orgFullName"] ?? "",
      orgTitle: user.profile?.["custom:orgTitle"] ?? "",
    },
    resolver: yupResolver(userSchema.required()),
  });
  const [showModal, setShowModal] = useState(false);
  const [isSkipDisabled, setIsSkipDisabled] = useState(true);
  const [updateUser, updateUserMutation] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setShowModal(false);
    },
  });

  const formErrors = formMethods?.formState?.errors;

  const mutationError = isEmpty(updateUserMutation.error) ? null :
    <span>An error occurred. Please try again later.</span>;

  useEffect(() => {
    const {profile} = user;
    if (isEmpty(profile?.["custom:orgFullName"]) || isEmpty(profile?.["custom:orgTitle"])) {
      setShowModal(true);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => setIsSkipDisabled(false), 5000);
  }, []);

  const handleUpdate = async (data) => {
    await updateUser({variables: {username: user.email, customerName: user.customerName, ...data}});
    updateCustomerProfile(data);
  };

  const handleClose = (event, reason) => {
    if (["backdropClick", "escapeKeyDown"].includes(reason)) return;
    setShowModal(false);
  };

  const iconForSkipNowButton = isSkipDisabled ?
    <MoreHoriz
      sx={(theme) => ({
        "@keyframes blink": {"from, to": {opacity: 1}, "50%": {opacity: 0}},
        animation: "blink 1s ease-in infinite",
        paddingTop: theme.spacing(0.5),
      })}
    /> :
    <ArrowRightAlt sx={(theme) => ({paddingBottom: theme.spacing(0.25)})} />;

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{sx: {padding: 1}}}
    >
      <DialogTitle sx={{alignSelf: "center"}}>
        Welcome to BreachRx Cyber RegScout&trade;
      </DialogTitle>
      <DialogContent>
        Please complete your free registration to access all that Cyber RegScout has to offer.
        <FormProvider {...formMethods}>
          <form>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={1}
              mt={1}
              id="user-info-modal-form-wrapper"
            >
              <TextField
                {...formMethods.register("orgFullName", {required: true})}
                label="Organization Name"
                variant="outlined"
                size="medium"
                sx={{marginTop: 2, width: "100%"}}
                InputLabelProps={{sx: {userSelect: "none"}}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                  sx: {borderRadius: 0},
                }}
                error={!!formErrors?.orgFullName?.message}
                helperText={formErrors?.orgFullName?.message}
              />
              <TextField
                {...formMethods.register("orgTitle", {required: true})}
                label="Title"
                variant="outlined"
                size="medium"
                sx={{marginTop: 2, width: "100%"}}
                InputLabelProps={{sx: {userSelect: "none"}}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                  sx: {borderRadius: 0},
                }}
                error={!!formErrors?.orgTitle?.message}
                helperText={formErrors?.orgTitle?.message}
              />
            </Box>
          </form>
        </FormProvider>
        {(mutationError) &&
            <center>
              <Typography color="error" sx={{marginTop: 2, marginBottom: -2}}>
                {mutationError}
              </Typography>
            </center>
        }
      </DialogContent>
      <DialogActions sx={{marginRight: 2, paddingBottom: 2}}>
        <Button
          variant="outlined"
          onClick={formMethods.handleSubmit(handleUpdate)}
          disabled={updateUserMutation.loading}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          color="secondary"
          disabled={isSkipDisabled || updateUserMutation.loading}
        >
          Skip for Now {iconForSkipNowButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MandatoryUserDataModal;
