import React, {useRef} from "react";
import PropTypes from "prop-types";
import SunEditor from "suneditor-react";
import "./editor.css";
import {useMediaQuery} from "@mui/material";
import {useEditorStyles} from "./editorStyles";
import {smallDeviceWidth} from "../../resources/theme";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";

const DisplayEditor = ({content, padding="0", shadow=false}) => {
  let html = "";
  const isSmallDevice = useMediaQuery(`(max-device-width: ${smallDeviceWidth})`);
  const fontSize = isSmallDevice ? "font-size: 22px" : "font-size: 16px";
  const style = `font-family: Roboto, Helvetica, Arial, sans-serif; ${fontSize}; height: auto;`;
  const editor = useRef();
  const {isPaid} = useLogin();
  const {classes} = useEditorStyles({padding, hideToolbar: true, isPaid, shadow});
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  if (content) {
    html = content;
  }

  return (
    <span className={classes.displayEditor}>
      <SunEditor
        disable
        hideToolbar
        defaultValue={html}
        setAllPlugins={false}
        getSunEditorInstance={getSunEditorInstance}
        setDefaultStyle={style}
        setOptions={{
          showPathLabel: false,
          resizingBar: false,
          plugins: [],
        }}
      />
    </span>
  );
};

DisplayEditor.propTypes = {
  content: PropTypes.string.isRequired,
  padding: PropTypes.string,
  shadow: PropTypes.bool,
};

export {DisplayEditor};
