import React from "react";
import PropTypes from "prop-types";
import {
  Divider, FormControlLabel, ListItemIcon, ListItemText, MenuItem, Switch,
} from "@mui/material";
import Brightness2 from "@mui/icons-material/Brightness2";
import NetworkCheck from "@mui/icons-material/NetworkCheck";
import Person from "@mui/icons-material/Person";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import WbSunny from "@mui/icons-material/WbSunny";
import UpgradeProfileMenuBanner from "../../upgrade/UpgradeProfileMenuBanner";
import {useNavigate} from "react-router";
import {statusPage} from "../../../resources/environment";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {useTheme} from "../../../resources/ConsolidatedThemeProvider";

const ProfileMenuOptions = ({onClose}) => {
  const {logout} = useLogin();
  const navigate = useNavigate();
  const {activeTheme, toggleActiveTheme} = useTheme();
  const isDarkTheme = activeTheme.palette.mode === "dark";

  const handleLogout = () => {
    logout();
    onClose && onClose();
  };

  const handleNavigate = (url) => {
    navigate(url);
    onClose && onClose();
  };

  const handleStatus = () => {
    window.open(statusPage);
    onClose && onClose();
  };

  return (
    <>
      <UpgradeProfileMenuBanner handleNavigate={handleNavigate} />
      <MenuItem sx={{marginTop: 1}}>
        <ListItemIcon sx={{marginRight: 1, padding: 0}} onClick={() => toggleActiveTheme()}>
          {isDarkTheme ? <Brightness2 fontSize="small" /> : <WbSunny fontSize="small" />}
        </ListItemIcon>
        <FormControlLabel
          label={isDarkTheme ? "Dark Mode" : "Light Mode"}
          labelPlacement="start"
          control={
            <Switch
              size="small"
              checked={isDarkTheme}
              onChange={() => toggleActiveTheme()}
              inputProps={{"aria-label": "toggle theme"}}
              sx={{marginLeft: 1}}
            />
          }
          sx={{margin: 0}}
        />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleNavigate("/profile")}>
        <ListItemIcon sx={{marginRight: 1, padding: 0}}>
          <Person fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Manage Profile" />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleNavigate("/subscription")}>
        <ListItemIcon sx={{marginRight: 1, padding: 0}}>
          <ReceiptOutlined fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Manage Subscription" />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleStatus}>
        <ListItemIcon sx={{marginRight: 1, padding: 0}}>
          <NetworkCheck fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="BreachRx Status" />
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon sx={{marginRight: 1, padding: 0}}>
          <PowerSettingsNew fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </>
  );
};

ProfileMenuOptions.propTypes = {
  onClose: PropTypes.func,
};

export default ProfileMenuOptions;
