import React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import SidebarForAllViews from "../../components/sidebar/SidebarForAllViews";
import UserProfile from "../user/UserProfile";
import {OverviewProvider} from "../../components/overview/OverviewProvider";
import {Home, Detail, RegulationsProvider} from "../../pages/regulations";
import PricingPage from "../pricing";
import ManageSubscription from "../pricing/ManageSubscription";
import MandatoryUserDataModal from "../../components/login/MandatoryUserDataModal";
import NewsFeed from "../newsFeed";

const RegulationsCore = () => (
  <RegulationsProvider>
    <OverviewProvider>
      <RegulationsContent />
    </OverviewProvider>
  </RegulationsProvider>
);

const RegulationsContent = () => {
  const location = useLocation();
  const locationIncludesPayment = location.search.includes("?payment=successful");

  return (
    <Box display="flex" width={"100%"}>
      <Box id="sidebar-container">
        <SidebarForAllViews />
      </Box>
      <MandatoryUserDataModal />
      <Routes>
        <Route path="/">
          <Route
            path=":payment?"
            element={
            locationIncludesPayment ? <Navigate to="/subscription?payment=successful" /> : <Home />
            }
          />
        </Route>
        <Route path="/subscription" element={<ManageSubscription />} />
        <Route path="/news" element={<NewsFeed />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="regulation/:id" element={<Detail />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Box>
  );
};

export default RegulationsCore;
