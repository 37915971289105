import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Chip, Divider, Link, Typography} from "@mui/material";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {inverseTextColor} from "../../resources/theme";

const UpgradeProfileMenuBanner = ({handleNavigate}) => {
  const {currentPlanForDisplay} = useLogin();

  const atHighestLevel = (["Global", "Enterprise"].includes(currentPlanForDisplay));

  if (atHighestLevel) return null;

  return (
    <>
      <Box width="100%" px={2} pb={2} ml={2.5}>
        <Chip
          label={`${currentPlanForDisplay} Plan`}
          sx={{borderRadius: 5, fontSize: "20px", marginBottom: 0.5}}
        />
        <Typography variant="body1" sx={{lineHeight: 0.8, marginTop: 1, userSelect: "none"}}>
            Want to do more with Cyber RegScout&trade;?
        </Typography>
        <Typography variant="caption" sx={{userSelect: "none"}}>
            Upgrade your plan to unlock more features and functionality
        </Typography>
        <Box mt={1.25} mb={0.25}>
          <Button onClick={() => handleNavigate("/pricing")} variant="contained" color="success">
            Upgrade my plan
          </Button>
          <span style={{userSelect: "none"}}> or </span>
          <Link
            onClick={() => handleNavigate("/subscription")}
            underline="always"
            sx={(theme) => ({
              color: inverseTextColor(theme.palette.mode),
              cursor: "pointer",
              textDecorationColor: "unset",
            })}
          >
            Manage my plan
          </Link>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

UpgradeProfileMenuBanner.propTypes = {
  handleNavigate: PropTypes.func,
};

export default UpgradeProfileMenuBanner;
