import React from "react";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import {useDetailData} from "../contexts/DetailDataProvider";
import {Box, Typography} from "@mui/material";
import NotificationBlock from "./NotificationBlock";
import NotificationTemplate from "./NotificationTemplate";
import RegulatorLeader from "./RegulatorLeader";
import {DisplayEditor} from "../../../components/editor/DisplayEditor";
import {
  getDeadlineString, getNotificationDeadlines, notificationTypeDisplay,
} from "../utils/dataTransformers";
import {isEmptyHTML} from "../../../shared/stringUtils";
import {intersection, isEmpty, map} from "underscore";
import {useDetailStyles} from "./styles/detailStyles";

const Notifications = () => {
  const {data} = useDetailData();
  const {groups} = useLogin();
  const {classes} = useDetailStyles();

  const {notifications, tags} = data;

  const needsUpgrade = isEmpty(intersection(groups, [
    "Admin", "Professional", "Global", "Enterprise",
  ]));
  const notificationSet = (isEmpty(notifications) || notifications.length < 2) && needsUpgrade ?
    [...(notifications ?? []), {notification: {type: "OTHER", conditions: "", content: ""}}] :
    notifications;

  const notificationDeadlines = getNotificationDeadlines(tags);

  if (isEmpty(notificationSet) && !needsUpgrade) return null;

  return (
    <Box>
      <Typography className={classes.title} paddingBottom={1} variant="h5" id = "notifications">
        Notifications
      </Typography>
      <Box className={classes.notificationsWrapper}>
        {map(notificationSet, ({notification}, index) => {
          const affectedByUpgrade = notification.type !== "REGULATOR";
          const isAdvertisement = notification.type === "OTHER";
          const deadline = notificationDeadlines[notification.type];
          const hasCondition = !isEmptyHTML(notification.conditions);
          const hasContent = !isEmptyHTML(notification.content);
          const hasLeader = !isEmpty(data?.leader?.name);
          const hasNothingToShow = !deadline && !hasCondition && !hasContent && !hasLeader;
          if (hasNothingToShow && !affectedByUpgrade) return null;
          return (
            <Box key={index} mb={1}>
              <Box
                display={hasNothingToShow && !isAdvertisement ? "none" : undefined}
                className={classes.notificationTitle}
              >
                <Typography variant="h6" color="primary.contrastText">
                  <b>{notificationTypeDisplay[notification.type]}</b>
                </Typography>
              </Box>
              <NotificationBlock
                title="Deadline"
                isVisible={deadline}
                needsUpgrade={needsUpgrade && affectedByUpgrade}
                content={
                  <Typography paddingBottom={1} variant="body1" sx={{color: "text.primary"}}>
                    {getDeadlineString(deadline)}
                  </Typography>
                }
              />
              <NotificationBlock
                title="Conditions"
                isVisible={hasCondition}
                needsUpgrade={needsUpgrade && affectedByUpgrade}
                content={<DisplayEditor content={notification.conditions} />}
              />
              <NotificationBlock
                title="Description"
                isVisible={hasContent}
                needsUpgrade={needsUpgrade && affectedByUpgrade}
                content={<DisplayEditor content={notification.content} />}
              />
              <NotificationBlock
                title="Contact"
                isVisible={notification.type === "REGULATOR" && hasLeader}
                content={<RegulatorLeader />}
              />
              <NotificationBlock
                title="Template"
                isVisible={!isEmpty(notification.templates?.[0]) && !isEmpty(data?.leader)}
                content={
                  <NotificationTemplate
                    template={notification.templates?.[0]}
                    type={notification.type}
                  />
                }
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Notifications;
