import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as yup from "yup";
import {Login} from "./Login";
import Cookies from "js-cookie";
import {clearCookieSession, getCookieSession, setCookieSession} from "../../../shared/cookies";
import {initializeMetricsForUser} from "../../../shared/metrics";
import jwtDecode from "jwt-decode";
import {intersection, isEmpty} from "underscore";

export const LoginContext = createContext({
  login: () => {},
  loginSchema: {},
  user: {},
  setUser: () => {},
  currentPlanForDisplay: "",
  groups: [],
  isPaid: false,
  refreshSession: () => {},
  logout: () => {},
  isDarkThemeSaved: false,
  forgotPassword: () => {},
  resetPassword: () => {},
  updateCustomerProfile: () => {},
});
const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const LoginProvider = ({children}) => {
  const [user, setUser] = useState(getCookieSession() ?? {});
  const [mfaChallenge, setMFAChallenge] = useState({});
  const [metricsInitialized, setMetricsInitialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshError, setRefreshError] = useState({});
  const isDarkThemeSaved = Cookies.get("theme") === "dark";

  useEffect(() => {
    const consoleTagline =
      "https://breachrx.com - Cyber RegScout™: regulatory intelligence, research, and response.";
    console.log(consoleTagline);
  }, []);

  useEffect(() => {
    if (isEmpty(user?.profile) || metricsInitialized) return;
    const org = user?.profile?.["customer:orgFullName"] ?? "unknown";
    initializeMetricsForUser(org, user?.email ?? user?.username);
    setMetricsInitialized(true);
  }, [user]);

  const loginSchema = yup.object().shape({
    username: yup.string().email().required(),
    password: yup.string().required(),
  }).required();

  const login = async (data, formMethods) => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${AUTH_ENDPOINT}/login`, {}, {auth: data});
      if (response.status === 200) {
        if (response.data?.ChallengeName === "SMS_MFA") {
          setIsSubmitting(false);
          setMFAChallenge({userData: data, authSession: response.data});
          return;
        };
        const profile = jwtDecode(response.data.AuthenticationResult.IdToken);
        setUser({email: data.username, ...response.data.AuthenticationResult, profile});
        if (data.isMemorable) {
          setCookieSession({username: data.username, ...response.data.AuthenticationResult});
        }
      }
    } catch (error) {
      formMethods.setFocus("username");
      formMethods.reset({username: "", password: ""});
      console.log(error);
      formMethods.setError("password", {
        type: "manual", message: error?.response?.data?.message ?? error?.message,
      });
    } finally {
      if (!isEmpty(refreshError)) setRefreshError({});
      setIsSubmitting(false);
    }
  };

  const refreshSession = async () => {
    try {
      if (!isEmpty(refreshError)) setRefreshError({});
      const response = await axios.post(
          `${AUTH_ENDPOINT}/token/refresh`,
          {
            AuthParameters: {
              REFRESH_TOKEN: user.RefreshToken,
              USERNAME: user.username,
            },
          },
      );
      if (response.status === 200) {
        const profile = response.data?.IdToken ? jwtDecode(response.data.IdToken) : undefined;
        const newUser = {...response.data, username: user.username ?? user.email, profile};
        setUser({email: user.username ?? user.email, ...newUser});
        setCookieSession(newUser);
        return response.data;
      } else {
        setRefreshError({message: "Unauthorized"});
        logout();
      };
    } catch (error) {
      console.log(error);
      setRefreshError(error?.response?.data?.message ?? error?.message ?? error);
      logout();
    }
  };

  const logout = async () => {
    clearCookieSession();
    setUser({});
  };

  const forgotPassword = async (data, formMethods, setIsSuccess) => {
    try {
      if (!isEmpty(refreshError)) setRefreshError({});
      const response = await axios.post(`${AUTH_ENDPOINT}/password/forgot`, {
        Username: data.username,
      });
      if ([200, 202].includes(response.status)) {
        setIsSuccess(true);
      }
    } catch (error) {
      console.log(error);
      formMethods.setError("username", {
        type: "manual", message: error?.response?.data?.message ?? error?.message,
      });
      setIsSuccess(false);
    }
  };

  const resetPassword = async (data, formMethods, setIsSuccess) => {
    try {
      if (!isEmpty(refreshError)) setRefreshError({});
      formMethods.setError("username", {type: "manual", message: ""});
      const response = await axios.post(`${AUTH_ENDPOINT}/password/forgot`, {
        Username: data.username,
        Password: data.password,
        ConfirmationCode: data.confirmationCode,
      });
      if ([200, 202].includes(response.status)) {
        formMethods.reset();
        setIsSuccess(true);
      }
    } catch (error) {
      formMethods.reset();
      console.log(error);
      formMethods.setError("username", {
        type: "manual", message: error?.response?.data?.message ?? error?.message,
      });
      setIsSuccess(false);
    }
  };

  const mfaSchema = yup.object().shape({
    mfaCode: yup.string().matches(/^[0-9]+$/).min(6).max(6),
  }).required();

  const submitMfa = async (data, formMethods) => {
    const username = mfaChallenge.userData.username;

    try {
      setIsSubmitting(true);
      const response = await axios.post(`${AUTH_ENDPOINT}/login/mfa`, {
        ChallengeName: "SMS_MFA",
        ChallengeResponses: {
          USERNAME: username,
          SMS_MFA_CODE: data.mfaCode,
        },
        Session: mfaChallenge.authSession.Session,
      });
      if (response.status === 200 && !isEmpty(response?.data)) {
        const profile = jwtDecode(response.data.AuthenticationResult.IdToken);
        setUser({email: username, ...response.data.AuthenticationResult, profile});
        if (mfaChallenge.userData.isMemorable) {
          setCookieSession({username: username, ...response.data.AuthenticationResult});
        }
        setMFAChallenge({});
      } else {
        formMethods.setError("mfaCode", {type: "manual", message: "Login unsuccessful"});
      }
    } catch (error) {
      formMethods.setError("mfaCode", {
        type: "manual",
        message: error?.response?.data?.message ?? error?.message ?? "Login unsuccessful",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateCustomerProfile = (newCustomerData) => {
    const newUser = {...user};
    if (newCustomerData?.customerName) {
      newUser.profile["custom:customerName"] = newCustomerData.customerName;
    };
    if (newCustomerData?.orgFullName) {
      newUser.profile["custom:orgFullName"] = newCustomerData.orgFullName;
    };
    if (newCustomerData?.orgTitle) {
      newUser.profile["custom:orgTitle"] = newCustomerData.orgTitle;
    };
    setUser(newUser);
  };

  const currentToken = user?.IdToken ? jwtDecode(user.IdToken) : {};
  const groups = currentToken?.["cognito:groups"] ?? [];
  const currentPlanForDisplay = groups.includes("Enterprise") ? "Enterprise" :
    groups.includes("Global") ? "Global" :
    groups.includes("Professional") ? "Professional" : "Foundations";
  const isAdmin = groups.includes("Admin");
  const isHighTier = groups.includes("Global") || groups.includes("Enterprise");
  const isPaid = !isEmpty(intersection(groups, ["Admin", "Professional", "Global", "Enterprise"]));

  const providerValues = {
    login,
    loginSchema,
    user,
    setUser,
    currentPlanForDisplay,
    groups,
    isAdmin,
    isHighTier,
    isPaid,
    refreshSession,
    refreshError,
    setRefreshError,
    logout,
    isDarkThemeSaved,
    forgotPassword,
    resetPassword,
    isSubmitting,
    mfaChallenge,
    setMFAChallenge,
    mfaSchema,
    submitMfa,
    updateCustomerProfile,
  };

  return <LoginContext.Provider value={providerValues}>
    {!isEmpty(user) ? children : <Login />}
  </LoginContext.Provider>;
};

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLogin = () => {
  return useContext(LoginContext);
};

export default LoginProvider;
