import React, {useState} from "react";
import {
  Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Tooltip, Typography,
} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowRightOutlined from "@mui/icons-material/KeyboardArrowRightOutlined";
import {useRegulations} from "../../../pages/regulations/contexts/RegulationsProvider";
import {useLogin} from "@regscout/ui/src/components/login/LoginProvider";
import ProfileMenuOptions from "./ProfileMenuOptions";
import {useAppControlsStyles} from "../controls/appControlsStyles";

const ProfileMenu = () => {
  const {sidebarOpen} = useRegulations();
  const {classes} = useAppControlsStyles({open: sidebarOpen});
  const {user} = useLogin();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem disablePadding>
        <Tooltip title="Settings" placement="right">
          <ListItemButton
            id="sidebar-profile-button"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            dense
            className={classes.button}
          >
            <ListItemIcon>
              <AccountCircleOutlined />
            </ListItemIcon>
            <ListItemText id="account">
              <Box className={classes.controlsItemTextWrapper}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="body2">
                    <b>{user.profile?.["custom:customerName"] ?? null}</b>
                  </Typography>
                  <Typography variant="body2">{user?.email ?? user?.username}</Typography>
                </Box>
                <Box sx={(theme) => ({color: theme.palette.action.active})} display="flex">
                  <KeyboardArrowRightOutlined />
                </Box>
              </Box>
            </ListItemText>
          </ListItemButton>
        </Tooltip>
      </ListItem>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "left"}}
        PaperProps={{sx: {borderRadius: 0}}}
        MenuListProps={{role: "menu"}}
      >
        <ProfileMenuOptions onClose={handleClose} />
      </Menu>
    </>
  );
};

export default ProfileMenu;
