import React, {useEffect, useRef} from "react";
import {DetailDataProvider, useDetailData} from "../contexts/DetailDataProvider";
import {Box} from "@mui/material";
import DetailSkeleton from "./DetailSkeleton";
import DetailContent from "./DetailContent";
import {isEmpty} from "underscore";

const DetailPage = () => {
  const {data, loading} = useDetailData();
  const topOfDetailPage = useRef();

  useEffect(() => {
    topOfDetailPage.current?.scrollIntoView({behavior: "instant"});
  }, []);

  return (
    <Box display="flex" width={"100%"}>
      <Box
        p={3}
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
        ref={topOfDetailPage}
      >
        {loading || !data || isEmpty(data) ? <DetailSkeleton /> : <DetailContent />}
      </Box>
    </Box>
  );
};

const Detail = () => (
  <DetailDataProvider>
    <DetailPage />
  </DetailDataProvider>
);

export default Detail;
